<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import DOMPurify from 'dompurify'
import { Memo } from '@element-plus/icons-vue'
import { type PropType, ref } from 'vue'
import type { Solution, SolutionRoughEstimate, SolutionTag } from '@/types/solution.type'
import type { SolutionAttributeDetail } from '@/types/solutionAttribute.type'
import { parseMessage } from '@/utils/text.helper'
import { downloadFile } from '@/utils/file.helper'
import { useSolutionStore } from '@/stores/solutionStore'

const props = defineProps({
  currentSolution: {
    type: Object as PropType<Solution>,
    required: true
  },
  attributes: {
    type: Array as PropType<SolutionAttributeDetail[]>,
    required: true
  },
  solutionTags: {
    type: Array as PropType<SolutionTag[]>,
    required: true
  },
  roughEstimates: {
    type: Array as PropType<SolutionRoughEstimate[]>,
    required: true
  }
})

const solutionStore = useSolutionStore()
const estimateDialogData = ref<SolutionRoughEstimate | null>(null)
const downloadingId = ref(-1)

function getImageUrl(solutionId: number, attributeId: number, imageId: number) {
  return solutionStore.get_image_url(solutionId, attributeId, imageId)
}
function textFormatter(txt: string) {
  return txt.replace(/\n/g, '<br>')
}
function openEstimateDialog(estimate: SolutionRoughEstimate) {
  estimateDialogData.value = estimate
}
function closeEstimateDialog() {
  estimateDialogData.value = null
}

async function downloadAttributeFile(attributeId: number) {
  if (downloadingId.value !== -1) {
    return
  }
  try {
    downloadingId.value = attributeId
    const { data, filename } = await solutionStore.download_attribute_pptx(
      props.currentSolution.id,
      attributeId
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  } finally {
    downloadingId.value = -1
  }
}
</script>

<template>
  <el-dialog
    :close-on-press-escape="false"
    :model-value="!!estimateDialogData"
    v-if="estimateDialogData"
    top="10vh"
    width="50%"
    @close="closeEstimateDialog()"
    class="ix-dialog"
  >
    <template #header>{{ estimateDialogData.title }}</template>
    <div class="estimate-dialog-container">
      <div class="estimate-dialog-note" v-html="parseMessage(estimateDialogData.note)" />
    </div>
    <template #footer>
      <span>
        <el-button @click="closeEstimateDialog">閉じる</el-button>
      </span>
    </template>
  </el-dialog>
  <div class="meta-info">
    <div class="subheader">説明</div>
    <div v-if="!currentSolution.description">準備中</div>
    <div
      class="description"
      v-html="DOMPurify.sanitize(textFormatter(currentSolution.description))"
    />
    <div class="tags">
      <el-tag
        v-for="tag in solutionTags"
        :key="tag.id"
        type="info"
        style="margin-right: 8px; margin-bottom: 8px"
      >
        {{ tag.title }}
      </el-tag>
    </div>
  </div>
  <el-divider />
  <div class="project-knowledge-wrapper">
    <div class="solution-attributes">
      <div class="subheader">選定ポイント</div>
      <div v-if="attributes.length === 0">準備中</div>
      <div v-for="attr in attributes" :key="attr.id" class="attribute-item-wrapper">
        <div class="subheader">
          <div class="attribute-item-title">{{ attr.title }}</div>
          <el-button
            v-if="attr.pptx"
            @click="downloadAttributeFile(attr.id)"
            :loading="downloadingId !== -1"
          >
            資料ダウンロード
          </el-button>
        </div>
        <div v-if="attr.images.length" class="attribute-item-images-wrapper">
          <div v-for="image in attr.images" :key="image.id" class="attribute-item-image">
            <img
              alt="選定ポイントの画像"
              :src="getImageUrl(currentSolution.id, attr.id, image.id)"
              crossOrigin="use-credentials"
            />
          </div>
        </div>
        <div class="attribute-item-note" v-html="DOMPurify.sanitize(textFormatter(attr.note))" />
      </div>
    </div>
    <div class="estimates">
      <div class="subheader">概算見積もり事例</div>
      <div v-if="!roughEstimates.length" class="estimates-placeholder">準備中</div>
      <div
        v-for="estimate in roughEstimates"
        :key="estimate.id"
        class="estimate-item"
        @click="openEstimateDialog(estimate)"
      >
        <el-icon class="estimate-icon" size="30">
          <Memo />
        </el-icon>
        <div class="estimate-info">
          <div class="estimate-item-title">{{ estimate.title }}</div>
          <div class="estimate-item-note">{{ estimate.note }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.meta-info {
  margin-top: 10px;
  .subheader {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
  }
}
.description {
  margin-bottom: 10px;
  margin-top: 10px;
}
/* attribute */
.project-knowledge-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;
}
.solution-attributes {
  width: calc(100% - 280px - 24px);
  .subheader {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
.attribute-item-wrapper {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: v-bind('colors.bg.gray02');
  gap: 8px;
  padding: 4px 0 24px 8px;
  border-left: 2px solid v-bind('colors.service.darkBlue');
  .subheader {
    display: flex;
    justify-content: space-between;
    height: 32px;
    line-height: 32px;
    .attribute-item-title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .attribute-item-images-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow-x: auto;
    padding-bottom: 12px;
    .attribute-item-image {
      width: 45%;
      min-width: 500px;
      flex-shrink: 0;
      min-height: 35vh;
      border: 1px solid v-bind('colors.border.base');
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .attribute-item-note {
    font-size: 14px;
    color: v-bind('colors.text.base');
  }
}
/* estimates */
.estimates {
  width: 280px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .subheader {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
.estimate-item {
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px 4px;
  border: 1px solid v-bind('colors.border.base');
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .estimate-icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
  }
  .estimate-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 4px;
    padding-right: 4px;
    .estimate-item-title {
      font-size: 14px;
      min-height: 20px;
      height: auto;
      line-height: 20px;
      font-weight: bold;
      color: v-bind('colors.text.base');
    }
    .estimate-item-note {
      font-size: 12px;
      line-height: 16px;
      color: v-bind('colors.text.lighter');
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
