<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useSolutionStore } from '@/stores/solutionStore'
import { computed, ref } from 'vue'
import { getHashedId } from '@/utils/hash.helper'
import { useRouter } from 'vue-router'

const router = useRouter()
const solutionStore = useSolutionStore()
const solutions = computed(() => solutionStore.solutions)
const tags = computed(() => {
  return [
    { id: -1, title: 'all' }, // 全選択用のtag
    ...solutionStore.solution_tags
  ]
})
const solutionQuery = ref({
  page: 1,
  limit: 10
})
const checkedTagIds = ref<number[]>([])

function openSolution(id: number) {
  router.push(`/projects?sId=${getHashedId(id)}`)
}

function getSolutions() {
  solutionStore.get_solutions({
    ...solutionQuery.value,
    tagIds: checkedTagIds.value
  })
}

function toggleFilterTag(tagId: number) {
  if (tagId === -1) {
    checkedTagIds.value = []
    getSolutions()
    return
  }
  if (checkedTagIds.value.includes(tagId)) {
    checkedTagIds.value = checkedTagIds.value.filter((id) => id !== tagId)
  } else {
    checkedTagIds.value.push(tagId)
  }
  solutionQuery.value.page = 1
  getSolutions()
}

// pagination
const totalPages = computed(() => {
  let num = 1
  if (solutionStore.solution_stats) {
    num = solutionStore.solution_stats.totalPages
  }
  return num
})
const currentPage = computed({
  get: () => solutionQuery.value.page,
  set: (val) => (solutionQuery.value.page = val)
})
function changePage(v: number) {
  solutionQuery.value.page = v
  getSolutions()
}

getSolutions()
solutionStore.get_solution_tags()
</script>

<template>
  <div class="solution-list-container">
    <div>
      <h1 class="title">ソリューション一覧</h1>
      <div class="container">
        <div class="tags-zone">
          <span v-for="tag in tags" :key="tag.id">
            <el-check-tag
              v-if="tag.id !== -1"
              class="tag"
              :checked="checkedTagIds.includes(tag.id)"
              type="primary"
              @change="toggleFilterTag(tag.id)"
            >
              {{ tag.title }}
            </el-check-tag>
            <el-check-tag
              v-else
              class="tag"
              :checked="checkedTagIds.length === 0"
              type="primary"
              @change="toggleFilterTag(tag.id)"
            >
              {{ tag.title }}
            </el-check-tag>
          </span>
        </div>
        <div class="table-container">
          <div class="ix-table">
            <div
              class="ix-table-row clickable"
              v-for="(row, i) in solutions"
              :key="row.id"
              @click="openSolution(row.id)"
            >
              <div class="ix-table-column th">#{{ i + 1 }}</div>
              <div class="ix-table-column">
                <div class="solution-title">
                  {{ row.title }}
                </div>
                <div class="solution-description">
                  {{ row.description }}
                </div>
              </div>
              <div class="ix-table-column">
                <el-tag type="info" v-for="tag in row.solutionTags.slice(0, 4)" :key="tag.id">
                  {{ tag.title }}
                </el-tag>
                <span v-if="row.solutionTags.length > 4">...</span>
              </div>
            </div>
            <div v-if="!solutions.length" class="row-placeholder">
              ソリューションが見つかりませんでした
            </div>
          </div>
        </div>
        <div class="pagination-footer">
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :hide-on-single-page="true"
            :page-count="totalPages"
            :current-page="currentPage"
            @current-change="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.el-container {
  overflow: hidden;
}
.el-header {
  padding: 0;
}
.el-main {
  padding-top: 16px;
}
.el-tag {
  margin-right: 10px;
}
.el-divider--horizontal {
  margin-bottom: 0;
  margin-top: 10px;
}
.el-check-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid v-bind('colors.border.base');
}
.ix-table-column {
  display: flex;
}
.ix-table-column.th {
  min-width: 50px;
}
.ix-table-column:last-child {
  display: flex;
  align-items: center;
  justify-content: end;
}
.solution-list-container {
  margin-top: 20px;
}
.solution-title {
  width: 200px;
  font-weight: bold;
}
.solution-description {
  width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
.container {
  display: flex;
  flex-direction: column;
}
.tags-zone {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}
.tag {
  margin-right: 5px;
  margin-top: 10px;
  font-size: 12px;
}
</style>
