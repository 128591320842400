<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useSolutionStore } from '@/stores/solutionStore'
import type {
  CreateSolutionPayload,
  CreateSolutionTagPayload,
  GetSolutionsQuery,
  SolutionOption,
  SolutionTag,
  UpdateSolutionOptionPayload,
  UpdateSolutionTagPayload
} from '@/types/solution.type'
import { useUserStore } from '@/stores/userStore'

const router = useRouter()
const solutionStore = useSolutionStore()
const userStore = useUserStore()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

let getSolutionsQuery = ref<GetSolutionsQuery>({
  tobeItemId: undefined
})
solutionStore.get_solutions(getSolutionsQuery.value)
solutionStore.get_solution_options()
solutionStore.get_solution_tags()
const solutions = computed(() => solutionStore.solutions)
const options = computed(() => solutionStore.solution_options)
const solutionTags = computed(() => solutionStore.solution_tags)
const isSubmitting = ref(false)
// create
let isCreateMode = ref(false)
let targetResource = ref<'solution' | 'option'>('solution')
let dialogCreateData = ref<CreateSolutionPayload>({
  title: '',
  description: ''
})
function openCreateDialog(target: 'solution' | 'option') {
  dialogCreateData.value = {
    title: '',
    description: ''
  }
  isCreateMode.value = true
  targetResource.value = target
}
function submitCreate() {
  if (!dialogCreateData.value.title.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  if (targetResource.value == 'solution') {
    solutionStore
      .create_solution(dialogCreateData.value)
      .then(() => {
        isCreateMode.value = false
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        isSubmitting.value = false
      })
  } else {
    solutionStore
      .create_solution_option(dialogCreateData.value)
      .then(() => {
        isCreateMode.value = false
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }
}

// update option
let isUpdateMode = ref(false)
let dialogUpdateData = ref<UpdateSolutionOptionPayload>({
  id: -1,
  title: ''
})
function openUpdateDialog(option: SolutionOption) {
  dialogUpdateData.value = {
    id: option.id,
    title: option.title
  }
  isUpdateMode.value = true
}
function submitUpdateOption() {
  if (dialogUpdateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateData.value.title.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  solutionStore
    .update_solution_option(dialogUpdateData.value)
    .then(() => {
      isUpdateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteOption() {
  if (dialogUpdateData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', 'オプションの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore
        .delete_solution_option(dialogUpdateData.value.id)
        .then(() => {
          isUpdateMode.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}
// solution tags
const addSolutionTagDialog = ref(false)
const updateSolutionTagDialog = ref(false)
const dialogAddSolutionTagData = ref<CreateSolutionTagPayload>({
  title: ''
})
const dialogUpdateSolutionTagData = ref<UpdateSolutionTagPayload>({
  id: -1,
  title: ''
})

function openAddSolutionTagDialog() {
  dialogAddSolutionTagData.value = {
    title: ''
  }
  addSolutionTagDialog.value = true
}

function openUpdateSolutionTagDialog(tag: SolutionTag) {
  dialogUpdateSolutionTagData.value = {
    id: tag.id,
    title: tag.title
  }
  updateSolutionTagDialog.value = true
}

function createSolutionTag() {
  if (!dialogAddSolutionTagData.value.title) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  solutionStore
    .create_solution_tag(dialogAddSolutionTagData.value)
    .then(() => {
      addSolutionTagDialog.value = false
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}

function updateSolutionTag() {
  if (!dialogUpdateSolutionTagData.value.title) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  solutionStore
    .update_solution_tag(dialogUpdateSolutionTagData.value)
    .then(() => {
      updateSolutionTagDialog.value = false
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}

function confirmDeleteTag() {
  if (dialogUpdateSolutionTagData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  _ElConfirm('この処理は取り消せません。実行しますか？', 'タグの削除', {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      solutionStore
        .delete_solution_tag(dialogUpdateSolutionTagData.value.id)
        .then(() => {
          updateSolutionTagDialog.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}

// transit
function openDetailPage(solutionId: number) {
  router.push('/catalogs?menu=solutionList&resourceId=' + solutionId)
}
</script>

<template>
  <div id="solution-list">
    <!-- dialog -->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header
          >{{ targetResource == 'solution' ? 'ソリューション' : 'オプション' }}の新規登録</template
        >
        <el-form
          :model="dialogCreateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="名前" required>
            <el-input v-model="dialogCreateData.title" />
          </el-form-item>
          <el-form-item label="説明">
            <el-input v-model="dialogCreateData.description" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isCreateMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitCreate">保存する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>オプションの編集</template>
        <el-form
          :model="dialogUpdateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogUpdateData.title" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteOption()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="isUpdateMode = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateOption">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="addSolutionTagDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>タグの追加</template>
        <el-form
          :model="dialogUpdateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogAddSolutionTagData.title" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link @click="addSolutionTagDialog = false">閉じる</el-button>
            </span>
            <span>
              <el-button :loading="isSubmitting" @click="createSolutionTag">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="updateSolutionTagDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>タグの編集</template>
        <el-form
          :model="dialogUpdateSolutionTagData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogUpdateSolutionTagData.title" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteTag()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="updateSolutionTagDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="updateSolutionTag">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>
    <!-- template -->
    <div class="subheader">
      <div class="subheader-title">ソリューション</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="openCreateDialog('solution')">新規登録</el-button>
      </div>
    </div>
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in solutions"
        :key="row.id"
        @click="openDetailPage(row.id)"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.title }}</div>
      </div>
      <div v-if="!solutions.length" class="row-placeholder">データ未登録です</div>
    </div>
    <el-divider />
    <div class="subheader">
      <div class="subheader-title">オプション</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="openCreateDialog('option')">新規登録</el-button>
      </div>
    </div>
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in options"
        :key="row.id"
        @click="isAdmin ? openUpdateDialog(row) : undefined"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.title }}</div>
      </div>
      <div v-if="!options.length" class="row-placeholder">データ未登録です</div>
    </div>
    <el-divider />
    <div class="subheader">
      <div class="subheader-title">タグ</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="openAddSolutionTagDialog">新規登録</el-button>
      </div>
    </div>
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in solutionTags"
        :key="row.id"
        @click="isAdmin ? openUpdateSolutionTagDialog(row) : undefined"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.title }}</div>
      </div>
      <div v-if="!solutionTags.length" class="row-placeholder">データ未登録です</div>
    </div>
  </div>
</template>
<style scoped>
#solution-list {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
</style>
