<script setup lang="ts">
import { computed, watch, onMounted, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useSortable } from '@vueuse/integrations/useSortable'
import { useWorksheetStore } from '@/stores/worksheetStore'
import { Plus, CircleClose } from '@element-plus/icons-vue'
import type {
  BulkUpdateWorksheetCellValuePayload,
  CreateWorksheetColumnPayload,
  WorksheetCellValue,
  WorksheetColumnDetail
} from '@/types/worksheet.type'
import type { AddSolutionPayload, Product } from '@/types/product.type'
import { useProductStore } from '@/stores/productStore'
import { nextTick } from 'vue'
import { downloadFile } from '@/utils/file.helper'
import { useSolutionStore } from '@/stores/solutionStore'
import { useUserStore } from '@/stores/userStore'

const props = defineProps<{
  solutionId: number
  title: string
  canEdit: boolean
  isImportantColumnsOnly: boolean
  targetProductIds?: number[]
}>()
const emit = defineEmits<{
  (e: 'openWorksheetDetail'): void
  (e: 'toggleSelectedProduct', productId: number): void
}>()

const isAdmin = computed(() => {
  return userStore.get_current_user.role == 'admin'
})
const productStore = useProductStore()
const worksheetStore = useWorksheetStore()
const solutionStore = useSolutionStore()
const userStore = useUserStore()
const currentUser = computed(() => userStore.current_user)
const filteredCandidates = ref<{ id: number; name: string }[]>([])
const products = computed(() => worksheetStore.worksheet?.products || [])
const workSheetData = computed(() =>
  worksheetStore.worksheetData(props.isImportantColumnsOnly, props.targetProductIds)
)
const solution = computed(() => solutionStore.current_solution)
const productCandidates = computed(() => {
  return productStore.products
    .filter((e) => {
      return !products.value.some((p) => p.id == e.id)
    })
    .map((x) => {
      return { id: x.id, name: x.name }
    })
})
function messageFormatter(text: string) {
  return text !== ''
    ? text.split('\n').join('<br/>')
    : `<div style="font-size: 0.75rem; color: ${colors.text.lighter}">未入力</div>`
}
const isSubmitting = ref(false)

function makeLoading(b: boolean) {
  isSubmitting.value = b
}

defineExpose({ makeLoading })

function init() {
  worksheetStore.get_worksheet({ solutionId: props.solutionId })
  worksheetStore.get_all_labels()
  productStore.get_products({ page: 1, limit: 100 })
  solutionStore.get_solution_detail(props.solutionId)
}
onMounted(() => {
  init()
})
watch(
  () => props.solutionId,
  () => {
    init()
  }
)

// Select Label
const dialogCreateNewLabelInput = ref('')
const labelCandidates = computed(() => {
  return worksheetStore.labels
    .filter((e) => {
      return !workSheetData.value.some((row) => row.rowKey.worksheetColumnLabel.id == e.id)
    })
    .map((x) => {
      return { id: x.id, name: x.name }
    })
})
function labelFilterMethod(query: string) {
  dialogCreateNewLabelInput.value = query
  if (query.length) {
    filteredCandidates.value = labelCandidates.value.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    })
    filteredCandidates.value.unshift({
      id: -1,
      name: `「${query}」を新規登録`
    })
  } else {
    filteredCandidates.value = labelCandidates.value
  }
}

// worksheetColumn
const isCreateColumnMode = ref(false)
const dialogCreateSelectedLabelId = ref<number>()
const dialogCreateColumnData = ref<CreateWorksheetColumnPayload>({
  solutionId: props.solutionId,
  worksheetColumnLabelId: -1,
  sortNumber: 0,
  isImportant: false
})
function openCreateWorksheetColumnDialog() {
  dialogCreateColumnData.value = {
    solutionId: props.solutionId,
    worksheetColumnLabelId: -1,
    sortNumber: workSheetData.value.length + 1,
    isImportant: false
  }
  dialogCreateSelectedLabelId.value = undefined
  isCreateColumnMode.value = true
}
async function submitCreateColumn() {
  try {
    if (dialogCreateSelectedLabelId.value === -1) {
      // create label
      if (dialogCreateNewLabelInput.value === '') {
        _ElMessage({ type: 'error', message: 'ラベル名を入力してください' })
        return
      }
      isSubmitting.value = true
      await worksheetStore
        .create_worksheet_column_label(dialogCreateNewLabelInput.value)
        .then((res) => {
          dialogCreateNewLabelInput.value = ''
          dialogCreateSelectedLabelId.value = res.id
        })
    } else if (!dialogCreateSelectedLabelId.value) {
      _ElMessage({ type: 'error', message: 'ラベルを入力してください' })
      return
    }

    // create column
    dialogCreateColumnData.value.worksheetColumnLabelId = dialogCreateSelectedLabelId.value
    worksheetStore.create_worksheet_column(dialogCreateColumnData.value).then(() => {
      isCreateColumnMode.value = false
    })
  } catch (e) {
    console.error(e)
  } finally {
    isSubmitting.value = false
  }
}
function toggleColumnImportance(rowKey: WorksheetColumnDetail) {
  if (isSubmitting.value) {
    return
  }
  isSubmitting.value = true
  _ElConfirm(
    `${rowKey.isImportant ? '重要項目設定をOFFにして簡易比較表から外しますか？' : '重要項目設定をONにして簡易比較表に掲載しますか？'}`,
    `「${rowKey.worksheetColumnLabel.name}」の重要項目設定`,
    {
      confirmButtonText: `${rowKey.isImportant ? 'OFFにする' : 'ONにする'}`,
      cancelButtonText: 'キャンセル'
    }
  )
    .then(() => {
      worksheetStore.toggle_worksheet_column_importannce(rowKey.id, !rowKey.isImportant)
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}
function removeColumn(rowKey: WorksheetColumnDetail) {
  if (isSubmitting.value) {
    return
  }
  isSubmitting.value = true
  _ElConfirm(
    'この処理は取り消せません。実行しますか？',
    `「${rowKey.worksheetColumnLabel.name}」を削除`,
    {
      confirmButtonText: '削除する',
      cancelButtonText: 'キャンセル'
    }
  )
    .then(() => {
      worksheetStore.remove_worksheet_column(rowKey.id)
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}

// product
const isAddProductMode = ref(false)
const isProductsFilterMode = ref(false)
const dialogCreateSelectedProductId = ref<number>()
const dialogCreateProductInput = ref('')
const dialogAddProductData = ref<AddSolutionPayload>({
  productId: -1,
  solutionId: props.solutionId
})
const filteredProducts = computed(() => {
  if (!props.targetProductIds || !props.targetProductIds.length) {
    return products.value
  }
  return products.value.filter((p) => props.targetProductIds?.some((id) => id === p.id))
})
function openAddProductDialog() {
  dialogAddProductData.value = {
    productId: -1,
    solutionId: props.solutionId
  }
  dialogCreateSelectedProductId.value = undefined
  isAddProductMode.value = true
}
function removeProduct(product: Product) {
  if (isSubmitting.value) {
    return
  }
  isSubmitting.value = true
  _ElConfirm('この処理は取り消せません。実行しますか？', `比較表から「${product.name}」を削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      productStore.remove_solution({ productId: product.id, solutionId: props.solutionId })
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}
function productFilterMethod(query: string) {
  dialogCreateProductInput.value = query
  if (query.length) {
    filteredCandidates.value = productCandidates.value.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    })
    filteredCandidates.value.unshift({
      id: -1,
      name: `「${query}」を新規登録`
    })
  } else {
    filteredCandidates.value = productCandidates.value
  }
}

async function addProductToWorksheet(productId: number) {
  dialogAddProductData.value.productId = productId
  await productStore.add_solution(dialogAddProductData.value).catch((e) => {
    console.error(e)
  })
  isAddProductMode.value = false
}

async function onClickSaveProduct() {
  try {
    if (dialogCreateSelectedProductId.value && dialogCreateSelectedProductId.value > -1) {
      isSubmitting.value = true
      await addProductToWorksheet(dialogCreateSelectedProductId.value)
    } else if (dialogCreateProductInput.value) {
      isSubmitting.value = true
      const res = await productStore.create_product(dialogCreateProductInput.value)
      dialogCreateProductInput.value = ''
      await addProductToWorksheet(res.id)
    } else {
      _ElMessage({ type: 'error', message: '商材を入力してください' })
    }
  } catch (e) {
    console.error(e)
  } finally {
    isSubmitting.value = false
  }
}

// cellValue
const cellEditContext = ref<{ rowName: string; columnName: string } | null>(null)
const isUpdateCellMode = computed(() => {
  return !!cellEditContext.value
})
const dialogUpdateCellData = ref<BulkUpdateWorksheetCellValuePayload>({ values: [] })
const relatedCellValues = computed(() => {
  return worksheetStore.worksheetCellCutData.filter((e) => e.solution.id !== props.solutionId)
})
const isPreparingUpdateCellDialog = ref(false)
async function openUpdateCellDialog(
  rowKey: WorksheetColumnDetail,
  product: Product,
  cell?: WorksheetCellValue
) {
  if (isPreparingUpdateCellDialog.value) {
    return
  }
  isPreparingUpdateCellDialog.value = true
  // load cellValues on other solution worksheets
  worksheetStore.get_single_product_cell_values(product.id)
  worksheetStore.get_columns_by_label_id(rowKey.worksheetColumnLabel.id)

  // create, if absent.
  const targetCell = await (async () => {
    if (!cell) {
      // create cellValue
      return worksheetStore
        .create_worksheet_cell_value({
          productId: product.id,
          worksheetColumnId: rowKey.id,
          note: '',
          isRequiredAttention: false
        })
        .then((res) => res)
        .catch(() => {
          isPreparingUpdateCellDialog.value = false
          throw new Error('セルの作成に失敗しました')
        })
    } else {
      return cell
    }
  })()
  dialogUpdateCellData.value = {
    values: [
      {
        id: targetCell.id,
        note: targetCell.note,
        isRequiredAttention: targetCell.isRequiredAttention
      }
    ]
  }
  cellEditContext.value = {
    rowName: rowKey.worksheetColumnLabel.name,
    columnName: product.name
  }
  isPreparingUpdateCellDialog.value = false
}
function closeUpdateCellDialog() {
  cellEditContext.value = null
}
function submitUpdateCell() {
  isSubmitting.value = true
  worksheetStore
    .bulk_update_worksheet_cell_values(dialogUpdateCellData.value)
    .then(() => {
      closeUpdateCellDialog()
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// sortColumns
const isSortMode = ref(false)
const sortdiv = ref<HTMLElement | null>(null)
const sortTargetColumns = ref<WorksheetColumnDetail[]>([])
function startSortColumnMode() {
  if (!workSheetData.value.length) {
    _ElMessage({ type: 'error', message: '比較表項目が未登録です' })
    return
  }
  isSortMode.value = true
  nextTick(() => {
    sortTargetColumns.value = [...workSheetData.value.map((e) => e.rowKey)]
    useSortable(sortdiv, sortTargetColumns, { animation: 150 })
  })
}
function submitSortColumns() {
  if (!sortTargetColumns.value.length) {
    return
  }
  isSubmitting.value = true
  worksheetStore
    .sort_solution_columns(
      props.solutionId,
      sortTargetColumns.value.map((x) => x.id)
    )
    .then(() => {
      isSortMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// sortProducts
const isSortProductMode = ref(false)
const sortProductDiv = ref<HTMLElement | null>(null)
const sortTargetProducts = ref<Product[]>([])
function startSortProductMode() {
  if (!products.value.length) {
    _ElMessage({ type: 'error', message: '商材が未登録です' })
    return
  }
  isSortProductMode.value = true
  nextTick(() => {
    sortTargetProducts.value = products.value
    useSortable(sortProductDiv, sortTargetProducts, { animation: 150 })
  })
}
function submitSortProducts() {
  if (!sortTargetProducts.value.length) {
    return
  }
  isSubmitting.value = true
  worksheetStore
    .sort_solution_products(
      props.solutionId,
      sortTargetProducts.value.map((x) => x.id)
    )
    .then(() => {
      isSortProductMode.value = false
    })
    .catch(() => {})
    .finally(() => {
      isSubmitting.value = false
    })
}

// 簡易比較表（column.isImportant)
const isSimpleWorksheetConfigMode = ref(false)
const simpleWorksheetConfigTargetColumns = ref<WorksheetColumnDetail[]>([])
function openSimpleWorksheetConfigDialog() {
  simpleWorksheetConfigTargetColumns.value = workSheetData.value.map((e) => e.rowKey)
  isSimpleWorksheetConfigMode.value = true
}
function submitSimpleWorksheetConfig(column: WorksheetColumnDetail) {
  isSubmitting.value = true
  worksheetStore.toggle_worksheet_column_importannce(column.id, column.isImportant)
  isSubmitting.value = false
}

// download
const isDownloading = ref(false)
function canDownload() {
  if (!currentUser.value) {
    return false
  }
  return currentUser.value.role === 'admin' || currentUser.value.role === 'supervisor'
}
async function download() {
  try {
    isDownloading.value = true
    const { data, filename } = await worksheetStore.download_worksheet({
      solutionId: props.solutionId
    })
    downloadFile(data, filename)
  } catch (error) {
    console.error(error)
  } finally {
    isDownloading.value = false
  }
}
</script>
<template>
  <div id="worksheet-full">
    <!-- dialog -->
    <template v-if="canEdit">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateColumnMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>比較表に項目を追加する</template>
        <el-form
          :model="dialogCreateColumnData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="ラベル">
            <el-select
              v-model="dialogCreateSelectedLabelId"
              value-key="id"
              filterable
              :filter-method="labelFilterMethod"
              placeholder="選択してください"
              style="width: 240px"
              default-first-option
            >
              <el-option
                v-for="item in filteredCandidates"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox
              v-model="dialogCreateColumnData.isImportant"
              label="重要項目として簡易比較表に掲載する"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isCreateColumnMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitCreateColumn">保存する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isAddProductMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>比較表に商材を登録する</template>
        <el-form
          :model="dialogAddProductData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="商材">
            <el-select
              v-model="dialogCreateSelectedProductId"
              value-key="id"
              filterable
              :filter-method="productFilterMethod"
              placeholder="選択してください"
              style="width: 240px"
              default-first-option
            >
              <el-option
                v-for="item in filteredCandidates"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isAddProductMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="onClickSaveProduct">保存する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateCellMode"
        v-if="cellEditContext"
        top="10vh"
        width="50%"
        class="ix-dialog"
        :before-close="closeUpdateCellDialog"
      >
        <template #header>比較表のセルを編集する</template>
        <div class="cell-edit-header">
          {{ cellEditContext.rowName }} - {{ cellEditContext.columnName }}
        </div>
        <el-form
          :model="dialogUpdateCellData"
          label-position="left"
          label-width="184px"
          @submit.prevent
        >
          <el-form-item :label="props.title">
            <el-input
              v-model="dialogUpdateCellData.values[0].note"
              type="textarea"
              :rows="3"
              placeholder="入力してください"
              style="width: 360px; margin-right: 20px"
            />
            <el-checkbox
              v-model="dialogUpdateCellData.values[0].isRequiredAttention"
              label="要注意マーク"
            />
          </el-form-item>
        </el-form>
        <div class="related-cells-wrapper">
          <div v-for="cell in relatedCellValues" :key="cell.column.id" class="related-cell">
            <div class="related-cell-title">{{ cell.solution.title }}</div>
            <div
              class="related-cell-value"
              v-html="messageFormatter(cell && cell.cellValue ? cell.cellValue.note : '')"
            />
          </div>
        </div>
        <template #footer>
          <span>
            <el-button link @click="closeUpdateCellDialog">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitUpdateCell">保存する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isSortMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>比較表の項目をドラッグ & ドロップで並び替えてください</template>
        <div ref="sortdiv" class="target-columns-wrapper">
          <div v-for="(column, i) in sortTargetColumns" :key="column.id" class="target-column sort">
            {{ i + 1 }}. {{ column.worksheetColumnLabel.name }}
          </div>
        </div>
        <template #footer>
          <span>
            <el-button link @click="isSortMode = false">キャンセル</el-button>
            <el-button :loading="isSubmitting" @click="submitSortColumns">反映する</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isSimpleWorksheetConfigMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>簡易比較表に掲載する項目を選択してください</template>
        <el-form
          :model="simpleWorksheetConfigTargetColumns"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <div class="target-columns-wrapper">
            <div
              v-for="(column, i) in simpleWorksheetConfigTargetColumns"
              :key="column.id"
              class="target-column"
            >
              <el-checkbox
                v-model="column.isImportant"
                :disabled="isSubmitting"
                :key="column.id"
                @change="submitSimpleWorksheetConfig(column)"
              >
                {{ i + 1 }}. {{ column.worksheetColumnLabel.name }}
              </el-checkbox>
            </div>
          </div>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isSimpleWorksheetConfigMode = false">閉じる</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="isSortProductMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>比較表の商材をドラッグ & ドロップで並び替えてください</template>
        <div ref="sortProductDiv" class="target-columns-wrapper">
          <div
            v-for="(product, i) in sortTargetProducts"
            :key="product.id"
            class="target-column sort"
          >
            {{ i + 1 }}. {{ product.name }}
          </div>
        </div>
        <template #footer>
          <span>
            <el-button link @click="isSortProductMode = false">キャンセル</el-button>
            <el-button :loading="isSubmitting" @click="submitSortProducts">反映する</el-button>
          </span>
        </template>
      </el-dialog>
    </template>
    <!-- product filter dialog -->
    <el-dialog
      :close-on-press-escape="false"
      v-model="isProductsFilterMode"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>比較表に掲載する項目を選択してください</template>
      <el-form :model="products" label-position="left" label-width="120px" @submit.prevent>
        <div class="target-columns-wrapper">
          <div v-if="!solution">Loading...</div>
          <div
            v-else
            v-for="({ product }, i) in solution.productSolutionMaps"
            :key="product.id"
            class="target-column"
          >
            <el-checkbox
              :model-value="
                filteredProducts.some((p) => p.id === product.id) &&
                targetProductIds &&
                targetProductIds.length > 0
              "
              :disabled="isSubmitting"
              :key="product.id"
              @change="emit('toggleSelectedProduct', product.id)"
            >
              {{ i + 1 }}. {{ product.name }}
            </el-checkbox>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span>
          <el-button link @click="isProductsFilterMode = false">閉じる</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- template -->
    <div class="topheader">
      <div v-if="props.isImportantColumnsOnly" class="topheader-important-worksheet-title">
        簡易比較表
      </div>
      <div v-else class="topheader-title">「{{ props.title }}」の比較表</div>
      <div class="topheader-action">
        <div v-if="isAdmin">
          <el-button v-if="canEdit" @click="startSortProductMode()">商材の並び替え</el-button>
          <el-button v-if="canEdit" @click="startSortColumnMode()">項目の並び替え</el-button>
          <el-button v-if="canEdit" @click="openSimpleWorksheetConfigDialog()">
            簡易比較表設定
          </el-button>
        </div>
        <el-button v-if="props.isImportantColumnsOnly" @click="emit('openWorksheetDetail')">
          詳細表示
        </el-button>
        <el-button v-if="!canEdit" @click="isProductsFilterMode = true">商材を絞り込む</el-button>
        <el-button v-show="canDownload()" :loading="isDownloading" @click="download">
          ダウンロード
        </el-button>
      </div>
    </div>
    <div class="worksheet-wrapper" v-if="filteredProducts.length > 0">
      <table>
        <thead>
          <tr class="worksheet-column-row first">
            <th class="product-column first">&nbsp;</th>
            <th
              v-for="p in filteredProducts"
              :key="p.id"
              :class="{ 'product-column': true, clickable: canEdit }"
            >
              {{ p.name }}
              <CircleClose
                v-if="canEdit && isAdmin"
                style="width: 1em; height: 1em; margin-left: 4px; cursor: pointer"
                @click="removeProduct(p)"
              />
            </th>
            <th v-if="canEdit && isAdmin" class="product-column new">
              <el-button link :icon="Plus" @click="openAddProductDialog()"> New </el-button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in workSheetData" :key="row.rowKey.id" class="worksheet-column-row">
            <th class="product-column first">
              <span
                :class="{ clickable: canEdit, important: row.rowKey.isImportant }"
                @click="
                  canEdit && isAdmin && !isSubmitting
                    ? toggleColumnImportance(row.rowKey)
                    : undefined
                "
                >{{ row.rowKey.worksheetColumnLabel.name }}</span
              >
              <CircleClose
                v-if="canEdit && isAdmin"
                style="width: 1em; height: 1em; margin-left: 4px; cursor: pointer"
                @click="removeColumn(row.rowKey)"
              />
            </th>
            <td
              v-for="(cell, i) in row.rowValues"
              :key="i"
              :class="{
                'product-column': true,
                'cell-value': true,
                clickable: canEdit,
                'required-attention': cell?.isRequiredAttention
              }"
              v-html="messageFormatter(cell ? cell.note : '')"
              @click="
                canEdit && isAdmin && !isPreparingUpdateCellDialog
                  ? openUpdateCellDialog(row.rowKey, products[i], cell ? cell : undefined)
                  : undefined
              "
            />
          </tr>
          <tr v-if="canEdit && isAdmin" class="worksheet-column-row">
            <td class="product-column first new">
              <el-button link :icon="Plus" @click="openCreateWorksheetColumnDialog">
                New
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>準備中</div>
  </div>
</template>
<style scoped>
#worksheet-full {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.topheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.topheader-title {
  font-size: 24px;
  font-weight: bold;
}
.topheader-important-worksheet-title {
  font-size: 16px;
  font-weight: bold;
}
.worksheet-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
}
thead {
  position: sticky;
  top: -18px; /* el-main のpadding-topと同じ */
  z-index: 2;
  background-color: v-bind('colors.bg.gray01');
}
.worksheet-column-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: auto;
  border-bottom: 2px solid v-bind('colors.border.base');
}
.worksheet-column-row.first > * {
  font-weight: bold;
}
.product-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 240px;
  padding: 8px 8px;
  font-size: 14px;
  color: v-bind('colors.text.base');
  line-height: 20px;
}
.product-column.first {
  width: 184px;
  font-weight: bold;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: v-bind('colors.bg.gray01');
  border-right: 2px solid v-bind('colors.border.base');
}
.product-column.first.new {
  border-right: 0;
}
.column-label {
  cursor: pointer;
}
.column-label.important {
  font-weight: bold;
  color: v-bind('colors.utility.red');
}
.clickable {
  cursor: pointer;
}
.product-column.cell-value {
  word-break: break-word;
}
.product-column.required-attention {
  background-color: v-bind('colors.utility.redBg');
}
.product-column.new {
  display: flex;
  flex-direction: column;
  width: 100px;
  color: v-bind('colors.text.black');
  line-height: 20px;
  justify-content: center;
  align-items: center;
}
.product-column.new img {
  margin-right: 4px;
  width: 20px;
  height: 20px;
  margin-top: -1px;
}
/* sort / simple columns */
.target-columns-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  padding-left: 12px;
}
.target-column {
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.sort {
  cursor: grab;
}
.sort:active {
  cursor: grabbing;
}
/* related cells */
.cell-edit-header {
  font-size: 16px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  text-align: center;
  margin-bottom: 8px;
  padding: 10px;
  background-color: v-bind('colors.bg.gray02');
  border-radius: 4px;
}
.related-cells-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid v-bind('colors.border.base');
}
.related-cell {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: v-bind('colors.text.base');
  border-top: 1px solid v-bind('colors.border.base');
}
.related-cell-title {
  font-weight: bold;
  font-size: 14px;
  width: 184px;
}
.related-cell-value {
  font-size: 12px;
  padding: 4px 8px;
  line-height: 20px;
}
</style>
