<script setup lang="ts">
export type MenuItem = 'projects' | 'catalogs' | 'cases' | 'settings'
import { useUsefulMaterialStore } from '@/stores/usefulMaterialStore'
import { useUserStore } from '@/stores/userStore'
import type { UsefulMaterial } from '@/types/usefulMaterial.type'
import { colorPalette as colors } from '@/utils/enums'
import { downloadFile } from '@/utils/file.helper'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ArrowDownBold, Close } from '@element-plus/icons-vue'
import { useCsRequestStore } from '@/stores/csRequestStore'
import type { CreateCsRequestPayload } from '@/types/csRequest.type'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { QuestionFilled } from '@element-plus/icons-vue'

const props = withDefaults(
  defineProps<{
    currentMenu: MenuItem
  }>(),
  {
    currentMenu: 'projects'
  }
)
const router = useRouter()
const userStore = useUserStore()
const usefulMaterialStore = useUsefulMaterialStore()
const csRequestStore = useCsRequestStore()
const isCsRequestMode = ref(false)
const isSubmitting = ref(false)

const dialogCsRequestData = ref<CreateCsRequestPayload>({
  text: ''
})

usefulMaterialStore.get_useful_materials({
  status: 'live'
})
const isAdminOrSupervisor = computed(() => {
  return userStore.get_current_user.role !== 'normal'
})
const materials = computed(() => usefulMaterialStore.usefulMaterials)

async function download(m: UsefulMaterial) {
  try {
    const { data, filename } = await usefulMaterialStore.download_useful_material(
      m.id,
      m.fileExtension
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  }
}
function transit(menu: MenuItem) {
  if (props.currentMenu === 'settings' && menu === 'settings' && !isAdminOrSupervisor.value) {
    router.push('/projects')
    return
  }
  router.push(`/${menu}`)
}

function openCsRequestDialog() {
  isCsRequestMode.value = true
}

function submitCreate() {
  if (!dialogCsRequestData.value.text.length) {
    _ElMessage({ type: 'error', message: 'お問い合わせ内容を入力してください' })
    return
  }

  isSubmitting.value = true

  csRequestStore
    .create_cs_request(dialogCsRequestData.value)
    .then(() => {
      isCsRequestMode.value = false
      dialogCsRequestData.value.text = ''
      isCsRequestMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function openRcPage() {
  let w = window.open()
  if (w) {
    w.opener = null
    w.location = 'https://resource-cloud.jp/'
  }
}

// presentation mode
function togglePresentationMode() {
  const currentUser = userStore.get_current_user
  const userId = currentUser.id
  const newPresentationMode = (() => {
    if (!currentUser.preferences) {
      return true
    }
    return !userStore.isPresentationMode
  })()
  userStore.update_user_preferences({
    id: userId,
    preferences: {
      ...currentUser.preferences,
      presentationMode: newPresentationMode
    }
  })
}
</script>

<template>
  <el-dialog
    :close-on-press-escape="false"
    v-model="isCsRequestMode"
    top="10vh"
    width="50%"
    class="ix-dialog"
  >
    <template #header>問い合わせを送る</template>
    <el-form :model="dialogCsRequestData" @submit.prevent>
      <el-form-item>
        <el-input v-model="dialogCsRequestData.text" type="textarea" :rows="5" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span>
        <el-button link @click="isCsRequestMode = false">閉じる</el-button>
        <el-button :loading="isSubmitting" @click="submitCreate">送信する</el-button>
      </span>
    </template>
  </el-dialog>
  <div class="header">
    <div class="logo" @click="transit('projects')">
      <img src="/assets/img/logo_navy.png" alt="Resource Cloud DX plus" width="110px" />
    </div>
    <div class="menu">
      <template v-if="isAdminOrSupervisor">
        <div
          class="menu-item"
          :class="{ active: currentMenu === 'projects' }"
          @click="transit('projects')"
        >
          Projects
        </div>
        <div
          class="menu-item"
          :class="{ active: currentMenu === 'cases' }"
          @click="transit('cases')"
        >
          Cases
        </div>
        <div
          v-if="isAdminOrSupervisor"
          class="menu-item"
          :class="{ active: currentMenu === 'catalogs' }"
          @click="transit('catalogs')"
        >
          Catalogs
        </div>
      </template>
      <template v-else-if="currentMenu !== 'settings'">
        <el-tooltip
          :content="`${userStore.isPresentationMode ? 'プレゼンモードをOFFにする' : 'プレゼンモードをONにする'}`"
          placement="bottom"
        >
          <div
            class="menu-item toggle"
            :class="{ toggleActive: userStore.isPresentationMode }"
            @click="togglePresentationMode()"
          >
            プレゼンモード{{ userStore.isPresentationMode ? 'ON' : 'OFF' }}
          </div>
        </el-tooltip>
        <el-dropdown>
          <div class="menu-item">
            <ArrowDownBold style="width: 16px; height: 16px" />
            お役立ち資料
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="m in materials" :key="m.id" @click="download(m)">
                {{ m.fileName }}.{{ m.fileExtension }}
              </el-dropdown-item>
              <el-dropdown-item :disabled="true" v-if="materials.length === 0"
                >資料を準備中です</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
      <div class="menu-item" @click="openRcPage()">
        <el-tooltip placement="bottom" content="Resource Cloudを開く" trigger="hover">
          <img src="@/assets/logo.svg" style="width: 20px; height: 20px" />
        </el-tooltip>
      </div>
      <div class="menu-item" v-if="!isAdminOrSupervisor" @click="openCsRequestDialog()">
        <QuestionFilled style="width: 20px; height: 20px" />
      </div>
      <div class="menu-item" @click="transit('settings')">
        <Close v-if="currentMenu === 'settings' && !isAdminOrSupervisor" class="close" />
        <img
          v-else-if="currentMenu === 'settings'"
          src="@/assets/ic-settings-current.png"
          srcset="@/assets/ic-settings-current@2x.png 2x, @/assets/ic-settings-current@3x.png 3x"
        />
        <img
          v-else
          src="@/assets/ic-settings.png"
          srcset="@/assets/ic-settings@2x.png 2x, @/assets/ic-settings@3x.png 3x"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  background-color: transparent;
}
.logo {
  display: flex;
  align-items: center;
  padding-top: 3px; /* ロゴの位置調整用マジックナンバー。ロゴのスタイルによって値が変わる。*/
  cursor: pointer;
}
.menu {
  display: flex;
  align-items: center;
  margin-right: 16px;
  gap: 16px;
}
.menu-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  cursor: pointer;
  &.active {
    color: v-bind('colors.service.accent');
    text-decoration: underline;
    text-underline-offset: 8px;
  }
  img {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
  &.close {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
  &.toggle {
    justify-content: center;
    width: 152px;
    padding: 8px 12px;
    &.toggleActive {
      background-color: v-bind('colors.service.darkBlue');
      color: v-bind('colors.text.white');
    }
  }
}
</style>
