<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import CommonSolutionKnowledgeContainer from '@/components/CommonSolutionKnowledgeContainer.vue'
import CommonWorksheet from '@/components/CommonWorksheet.vue'
import { useSolutionStore } from '@/stores/solutionStore'
import { computed, ref, watch } from 'vue'
import { Back } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  solutionIdHash?: string
  solutionId: number
}>()

const router = useRouter()
const solutionStore = useSolutionStore()
const currentSolution = computed(() => solutionStore.current_solution)
const roughEstimates = computed(() => solutionStore.roughEstimates)
const targetProductIds = ref<number[]>([])
const isWorksheetOpen = ref(false)

function toggleSelectedProduct(productId: number) {
  if (targetProductIds.value.includes(productId)) {
    targetProductIds.value = targetProductIds.value.filter((id) => id !== productId)
  } else {
    targetProductIds.value = [...targetProductIds.value, productId]
  }
}

solutionStore.clear_current_solution()
if (props.solutionId !== -1) {
  solutionStore.get_solution_detail(props.solutionId)
}

watch(
  () => props.solutionId,
  (newVal) => {
    solutionStore.clear_current_solution()
    if (newVal !== -1) {
      solutionStore.get_solution_detail(newVal)
    }
  }
)

watch(
  () => currentSolution.value,
  (newVal) => {
    if (newVal) {
      targetProductIds.value = newVal.productSolutionMaps.map((m) => m.productId)
    }
  }
)
</script>

<template>
  <el-dialog v-model="isWorksheetOpen">
    <template #header>詳細比較表</template>
    <div class="worksheet-modal">
      <div v-if="!currentSolution">Loading...</div>
      <CommonWorksheet
        v-else
        :solutionId="solutionId"
        :canEdit="false"
        :isImportantColumnsOnly="false"
        :title="currentSolution.title"
        :targetProductIds="targetProductIds"
        @toggle-selected-product="toggleSelectedProduct"
      />
    </div>
  </el-dialog>
  <div>
    <div class="ix-breadcrumb-navi">
      <div class="ix-breadcrumb-navi-item" @click="router.go(-1)">
        <Back style="width: 16px; height: 16px; margin-right: 4px" />戻る
      </div>
    </div>
    <template v-if="currentSolution">
      <h2 class="page-title" v-if="currentSolution">
        {{ currentSolution.title }}
      </h2>
      <el-divider />
      <div class="wrapper">
        <CommonSolutionKnowledgeContainer
          :rough-estimates="roughEstimates"
          :attributes="currentSolution.attributes"
          :solution-tags="currentSolution.solutionTags"
          :current-solution="currentSolution"
        />
        <el-divider />
        <div class="products">
          <CommonWorksheet
            ref="commonWorksheet"
            :solutionId="currentSolution.id"
            :title="currentSolution.title"
            :canEdit="false"
            :isImportantColumnsOnly="true"
            :target-product-ids="targetProductIds"
            @open-worksheet-detail="isWorksheetOpen = true"
            @toggle-selected-product="toggleSelectedProduct"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="placeholder">ソリューションが存在しません</div>
    </template>
  </div>
</template>

<style>
.el-container {
  height: 100%;
  overflow: hidden;
}
.el-header {
  padding: 0;
}
.worksheet-modal {
  padding: 12px 0;
}
.ix-breadcrumb-navi {
  margin-bottom: 15px;
}
.page-title {
  font-size: 24px;
  font-weight: bold;
}
.products {
  margin-bottom: 20px;
}
.tags {
  display: flex;
}
.tag {
  margin-right: 10px;
}
</style>
