import type {
  SolutionDetail,
  SolutionOption,
  CreateSolutionOptionPayload,
  UpdateSolutionOptionPayload,
  GetSolutionsQuery,
  CreateSolutionPayload,
  UpdateSolutionPayload,
  SolutionRoughEstimate,
  UpdateSolutionRoughEstimatePayload,
  CreateSolutionRoughEstimatePayload,
  SolutionTag,
  CreateSolutionTagPayload,
  UpdateSolutionTagPayload
} from '@/types/solution.type'
import axios from './api'
import { defineStore } from 'pinia'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type {
  CreateSolutionAttributePayload,
  UpdateSolutionAttributePayload,
  SolutionAttributeDetail
} from '@/types/solutionAttribute.type'
import { Constants } from '@/utils/enums'
import { extractFilename } from '@/utils/file.helper'
import type { PaginationStats } from '@/types/paginationStats.type'

interface State {
  current_solution: SolutionDetail | null
  solutions: SolutionDetail[]
  solution_options: SolutionOption[]
  solution_tags: SolutionTag[]
  solution_stats: PaginationStats | null
}

export const useSolutionStore = defineStore('solutionStore', {
  state: (): State => ({
    current_solution: null,
    solutions: [],
    solution_options: [],
    solution_tags: [],
    solution_stats: null
  }),
  getters: {
    get_image_url: () => (solutionId: number, attributeId: number, imageId: number) => {
      return `${Constants['BASE_URL']}/solutions/${solutionId}/attributes/${attributeId}/downloadPng/${imageId}`
    },
    attributes(state) {
      const arr = [...(state.current_solution?.attributes || [])]
      return arr.sort((a, b) => a.sortNumber - b.sortNumber)
    },
    roughEstimates(state) {
      return state.current_solution?.solutionRoughEstimates || []
    },
    current_solution_tags(state) {
      return state.current_solution?.solutionTags || []
    }
  },
  actions: {
    // Solution
    clear_current_solution() {
      this.current_solution = null
    },
    set_current_solution(data: SolutionDetail) {
      this.current_solution = data
    },
    get_solutions(query: GetSolutionsQuery) {
      axios
        .get('/solutions', { params: query })
        .then((response) => {
          this.solutions = response.data.items
          this.solution_stats = response.data.meta
        })
        .catch((error) => {
          console.error(error)
        })
    },
    get_solution_detail(id: number, skipSave = false) {
      axios
        .get(`/solutions/${id}`)
        .then((res: { data: SolutionDetail }) => {
          if (!skipSave) {
            this.current_solution = res.data
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    create_solution(json: CreateSolutionPayload) {
      return new Promise<SolutionDetail>((resolve, reject) => {
        axios
          .post('/solutions', json)
          .then((res: { data: SolutionDetail }) => {
            this.solutions.push(res.data)
            _ElMessage({ type: 'success', message: '保存しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '保存に失敗しました' })
            reject(error)
          })
      })
    },
    update_solution(json: UpdateSolutionPayload) {
      const { id, ...dto } = json
      return new Promise<SolutionDetail>((resolve, reject) => {
        axios
          .put(`/solutions/${id}`, dto)
          .then((res: { data: SolutionDetail }) => {
            this.$patch((state) => {
              const index = state.solutions.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.solutions[index] = res.data
              }
              if (state.current_solution?.id === id) {
                state.current_solution = res.data
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(error)
          })
      })
    },
    delete_solution(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/solutions/${id}`)
          .then(() => {
            this.$patch((state) => {
              const index = state.solutions.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.solutions = state.solutions.filter((item) => item.id !== id)
              }
              if (state.current_solution?.id === id) {
                state.current_solution = null
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    },
    // RoughEstimates
    create_solution_rough_estimate(
      createSolutionRoughEstimatePayload: CreateSolutionRoughEstimatePayload
    ) {
      const { id, ...payload } = createSolutionRoughEstimatePayload
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/solutions/${id}/roughEstimates`, payload)
          .then((res: { data: SolutionRoughEstimate }) => {
            this.$patch((state) => {
              if (state.current_solution?.id === id) {
                state.current_solution.solutionRoughEstimates.push(res.data)
              }
            })
            _ElMessage({ type: 'success', message: '登録しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '登録に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    update_solution_rough_estimate(json: UpdateSolutionRoughEstimatePayload) {
      const { id, solutionId, ...dto } = json
      return new Promise<void>((resolve, reject) => {
        axios
          .put(`/solutions/${solutionId}/roughEstimates/${id}`, dto)
          .then((res: { data: SolutionRoughEstimate }) => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                const index = state.current_solution.solutionRoughEstimates.findIndex(
                  (item) => item.id === id
                )
                if (index !== -1) {
                  state.current_solution.solutionRoughEstimates[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(err)
          })
      })
    },
    delete_solution_rough_estimate(id: number, solutionId: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/solutions/${solutionId}/roughEstimates/${id}`)
          .then(() => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                state.current_solution.solutionRoughEstimates =
                  state.current_solution.solutionRoughEstimates.filter((item) => item.id !== id)
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(err)
          })
      })
    },
    // SolutionAttributes
    create_solution_attribute(json: CreateSolutionAttributePayload) {
      const { solutionId, ...dto } = json
      return new Promise<SolutionAttributeDetail>((resolve, reject) => {
        axios
          .post(`/solutions/${solutionId}/attributes`, dto)
          .then((res: { data: SolutionAttributeDetail }) => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                state.current_solution.attributes.push(res.data)
              }
            })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '保存に失敗しました' })
            reject(error)
          })
      })
    },
    update_solution_attribute(json: UpdateSolutionAttributePayload) {
      const { id, solutionId, ...dto } = json
      return new Promise<SolutionAttributeDetail>((resolve, reject) => {
        axios
          .put(`/solutions/${solutionId}/attributes/${id}`, dto)
          .then((res: { data: SolutionAttributeDetail }) => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                const index = state.current_solution.attributes.findIndex((item) => item.id === id)
                if (index !== -1) {
                  state.current_solution.attributes[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(error)
          })
      })
    },
    delete_solution_attribute(solutionId: number, id: number, showMessage: boolean = true) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/solutions/${solutionId}/attributes/${id}`)
          .then(() => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                state.current_solution.attributes = state.current_solution.attributes.filter(
                  (item) => item.id !== id
                )
              }
            })
            if (showMessage) {
              _ElMessage({ type: 'success', message: '削除しました' })
            }
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    },
    sort_solution_attributes(solutionId: number, idsInOrder: number[]) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/solutions/${solutionId}/attributes/sort`, {
            idsInOrder
          })
          .then((res: { data: SolutionDetail }) => {
            this.current_solution = res.data
            _ElMessage({ type: 'success', message: '並び替えました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '並び替えに失敗しました' })
            reject(error)
          })
      })
    },
    upload_attribute_pptx(solutionId: number, attributeId: number, file: File) {
      const formData = new FormData()
      formData.append('file', file)
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/solutions/${solutionId}/attributes/${attributeId}/uploadPptx`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((res: { data: SolutionAttributeDetail }) => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                const index = state.current_solution.attributes.findIndex(
                  (item) => item.id === attributeId
                )
                if (index !== -1) {
                  state.current_solution.attributes[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: 'アップロードしました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: 'アップロードに失敗しました' })
            reject(error)
          })
      })
    },
    delete_attribute_pptx(solutionId: number, attributeId: number) {
      axios
        .delete(`/solutions/${solutionId}/attributes/${attributeId}/pptx`)
        .then(() => {
          this.$patch((state) => {
            if (state.current_solution?.id === solutionId) {
              const index = state.current_solution.attributes.findIndex(
                (item) => item.id === attributeId
              )
              if (index !== -1) {
                delete state.current_solution.attributes[index].pptx
              }
            }
          })
          _ElMessage({ type: 'success', message: '削除しました' })
        })
        .catch((error) => {
          console.error(error)
          _ElMessage({ type: 'error', message: '削除に失敗しました' })
        })
    },
    download_attribute_pptx(solutionId: number, attributeId: number) {
      return new Promise<{ data: Blob; filename: string }>((resolve, reject) => {
        axios
          .get(`/solutions/${solutionId}/attributes/${attributeId}/downloadPptx`, {
            responseType: 'blob'
          })
          .then((res) => {
            const filename = extractFilename(res, 'download.pptx')
            resolve({ data: res.data, filename })
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: 'ダウンロードに失敗しました' })
            reject(error)
          })
      })
    },
    upload_attribute_png(solutionId: number, attributeId: number, file: File) {
      const formData = new FormData()
      formData.append('file', file)
      axios
        .post(`/solutions/${solutionId}/attributes/${attributeId}/uploadPng`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res: { data: SolutionAttributeDetail }) => {
          this.$patch((state) => {
            if (state.current_solution?.id === solutionId) {
              const index = state.current_solution.attributes.findIndex(
                (item) => item.id === attributeId
              )
              if (index !== -1) {
                state.current_solution.attributes[index] = res.data
              }
            }
          })
          _ElMessage({ type: 'success', message: 'アップロードしました' })
        })
        .catch((error) => {
          console.error(error)
          _ElMessage({ type: 'error', message: 'アップロードに失敗しました' })
        })
    },
    delete_attribute_png(solutionId: number, attributeId: number, imageId: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/solutions/${solutionId}/attributes/${attributeId}/images/${imageId}`)
          .then(() => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                const index = state.current_solution.attributes.findIndex(
                  (item) => item.id === attributeId
                )
                if (index !== -1) {
                  state.current_solution.attributes[index].images =
                    state.current_solution.attributes[index].images.filter(
                      (item) => item.id !== imageId
                    )
                }
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    },
    sort_attribute_images(solutionId: number, attributeId: number, imageIdsInOrder: number[]) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/solutions/${solutionId}/attributes/${attributeId}/sortImages`, {
            imageIdsInOrder
          })
          .then((res: { data: SolutionAttributeDetail }) => {
            this.$patch((state) => {
              if (state.current_solution?.id === solutionId) {
                const index = state.current_solution.attributes.findIndex(
                  (item) => item.id === attributeId
                )
                if (index !== -1) {
                  state.current_solution.attributes[index] = res.data
                }
              }
            })
            _ElMessage({ type: 'success', message: '並び替えました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '並び替えに失敗しました' })
            reject(error)
          })
      })
    },
    // SolutionOption
    clear_solution_options() {
      this.solution_options = []
    },
    get_solution_options() {
      axios
        .get('/solutions/options')
        .then((response) => {
          this.solution_options = response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    create_solution_option(json: CreateSolutionOptionPayload) {
      return new Promise<SolutionOption>((resolve, reject) => {
        axios
          .post('/solutions/options', json)
          .then((res) => {
            this.solution_options.push(res.data)
            _ElMessage({ type: 'success', message: '保存しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '保存に失敗しました' })
            reject(error)
          })
      })
    },
    update_solution_option(json: UpdateSolutionOptionPayload) {
      const { id, ...dto } = json
      return new Promise<SolutionOption>((resolve, reject) => {
        axios
          .put(`/solutions/options/${id}`, dto)
          .then((res) => {
            this.$patch((state) => {
              const index = state.solution_options.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.solution_options[index] = res.data
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(error)
          })
      })
    },
    delete_solution_option(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/solutions/options/${id}`)
          .then(() => {
            this.$patch((state) => {
              const index = state.solution_options.findIndex((item) => item.id === id)
              if (index !== -1) {
                state.solution_options = state.solution_options.filter((item) => item.id !== id)
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    },
    // SolutionTag
    get_solution_tags() {
      return new Promise<SolutionOption[]>((resolve, reject) => {
        axios
          .get('/solutions/tags')
          .then((response) => {
            this.$patch((state) => {
              state.solution_tags = response.data
            })
            resolve(response.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: 'タグの取得に失敗しました' })
            reject(error)
          })
      })
    },
    create_solution_tag(createSolutionTagPayload: CreateSolutionTagPayload) {
      return new Promise<SolutionTag>((resolve, reject) => {
        axios
          .post('/solutions/tags', { title: createSolutionTagPayload.title })
          .then((res) => {
            this.$patch((state) => {
              state.solution_tags.push(res.data)
            })
            _ElMessage({ type: 'success', message: '保存しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '保存に失敗しました' })
            reject(error)
          })
      })
    },
    update_solution_tag(updateSolutionTagPayload: UpdateSolutionTagPayload) {
      return new Promise<SolutionTag>((resolve, reject) => {
        axios
          .put(`/solutions/tags/${updateSolutionTagPayload.id}`, updateSolutionTagPayload)
          .then((res) => {
            this.$patch((state) => {
              const index = state.solution_tags.findIndex(
                (item) => item.id === updateSolutionTagPayload.id
              )
              if (index !== -1) {
                state.solution_tags[index] = res.data
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve(res.data)
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            reject(error)
          })
      })
    },
    delete_solution_tag(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/solutions/tags/${id}`)
          .then(() => {
            this.$patch((state) => {
              state.solution_tags = state.solution_tags.filter((item) => item.id !== id)
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    },
    add_tag_to_solution(solutionId: number, tagId: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/solutions/${solutionId}/add-tag`, { tagId })
          .then((res) => {
            this.$patch((state) => {
              if (state.current_solution) {
                state.current_solution.solutionTags = res.data.solutionTags
              }
            })
            _ElMessage({ type: 'success', message: '追加しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '追加に失敗しました' })
            reject(error)
          })
      })
    },
    remove_tag_from_solution(solutionId: number, tagId: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/solutions/${solutionId}/remove-tag/`, { tagId })
          .then(() => {
            this.$patch((state) => {
              if (state.current_solution) {
                state.current_solution.solutionTags = state.current_solution.solutionTags.filter(
                  (item) => item.id !== tagId
                )
              }
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            reject(error)
          })
      })
    }
  }
})
